import React, { useMemo } from 'react'

import { IMessengerChannel, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { Link } from 'components'
import { useMessenger, useFetchChannelMembers } from 'containers/Messenger/hooks'
import { MessengerChannelAvatar, MessengerItemInner, MessengerMenuConversation } from 'containers/Messenger/components'
// import style from './MessengerConversationChannel.module.css'

type MessengerChannelPropType = {
    classes?: string
    data: IMessengerChannel
    user: IUser
    onMarkRead: (id: string) => void
    onUnMarkRead: (id: string) => void
}

const MessengerConversationChannel: React.FC<MessengerChannelPropType> = ({
    classes,
    data,
    user,
    onMarkRead,
    onUnMarkRead,
}) => {
    const { isReceivedLastMessage, isReadLastMessage } = useMessenger()

    const { data: dataChannelMembers } = useFetchChannelMembers({
        channelId: data.channelId,
        withUsersInfo: true,
    })

    const isReceived = useMemo(() => {
        return isReceivedLastMessage(user.id, data.lastMessage)
    }, [data])

    const isRead = useMemo(() => {
        return isReadLastMessage(user.id, data.lastMessage)
    }, [data])

    const senderName = useMemo(() => {
        return data.lastMessage && data.lastMessage.senderUserId !== user.id
            ? `${data.lastMessage?.senderUser.name ?? ''} ${data.lastMessage?.senderUser.surname ?? ''}`
            : undefined
    }, [data])

    const urlChannel = useMemo(() => {
        return APP_URL.messengerChannel.replace(':id', data.channelId)
    }, [data])

    const handlerMarkRead = () => {
        onMarkRead(data.channelId)
    }

    const handlerMarkUnRead = () => {
        onUnMarkRead(data.channelId)
    }

    const avatarTpl = (
        <Link url={urlChannel}>
            <MessengerChannelAvatar
                userId={user.id}
                title={data.channel.title}
                image={data.channel.image}
                members={dataChannelMembers}
            />
        </Link>
    )

    const menuTpl = (
        <MessengerMenuConversation
            onMarkRead={data.unreadMessagesCount ? handlerMarkRead : undefined}
            onMarkUnRead={!data.unreadMessagesCount ? handlerMarkUnRead : undefined}
        />
    )

    return (
        <MessengerItemInner
            classes={classes}
            title={data.channel.title}
            avatar={avatarTpl}
            message={data.lastMessage ? (data.lastMessage.text || data.lastMessage.forwardedMessage) : ''}
            messageLines={2}
            menu={menuTpl}
            // FIXME in KW-184 KW-185
            countNew={data.unreadMessagesCount}
            date={data.lastMessage?.createdAt || data.createdAt}
            senderName={senderName}
            url={urlChannel}
            isSimpleMessage
            isMarkedUnread={data.isMarkedAsUnread}
            isReceived={isReceived}
            isRead={isRead}
            isError={false}
        />
    )
}

export default MessengerConversationChannel
