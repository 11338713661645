import React, { useRef } from 'react'
import cn from 'classnames'

import { Button } from 'components'
import style from './DatePickerNative.module.css'

type DatePickerNativePropType = {
    classesWrap?: string
    classes?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const DatePickerNative: React.FC<DatePickerNativePropType> = ({
    children,
    classesWrap,
    classes,
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const handlerClick = () => {
        if (inputRef.current && 'showPicker' in inputRef.current) {
            // @ts-ignore
            inputRef.current.showPicker()
        }
    }

    return (
        <div className={cn(style.datePicker, classesWrap)}>
            <Button
                classes={cn(style.datePickerAction, classes)}
                styleType="transparent"
                onClick={handlerClick}
            >
                {children}
            </Button>
            <input
                className={style.datePickerInput}
                type="date"
                ref={inputRef}
                {...props}
            />
        </div>
    )
}

export default DatePickerNative
