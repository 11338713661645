import React, {
    RefObject,
    useLayoutEffect,
    useEffect,
    useMemo,
    useRef,
    useState,
    createRef,
} from 'react'

import {
    IUser,
    IHistoryMessage,
    IHistoryMessageData,
    IHistoryMessageGroup,
    IMessengerUser,
    IMessengerWsEventMessageNew,
    IMessengerWsEventMessageStatus,
    IMessengerWsEventMessageError,
} from 'interfaces'
import { MessengerMessageStatus, MessengerMessageType } from 'enums'
import { MessengerMessageGroup, MessengerMessageAvatar, MessengerMessage } from 'containers/Messenger/components'
import { useMessenger } from 'containers/Messenger/hooks'
import { Loader } from 'components'
import { useIntersectionObserver } from 'hooks'
import { UserDto } from 'dto'
import styleMessage from 'containers/Messenger/components/MessengerMessage/MessengerMessage.module.css'
import style from './MessengerRoom.module.css'

type TWsMessage = IMessengerWsEventMessageNew | IMessengerWsEventMessageStatus | IMessengerWsEventMessageError

type MessengerBodyPropType = {
    parentRef: React.RefObject<HTMLDivElement>
    user: IUser
    users: IMessengerUser[]
    isLoading: boolean
    isStopLoad: boolean
    isAdmin?: boolean
    websocketMessage?: TWsMessage
    historyMessages?: IHistoryMessage[]
    onLoad: () => void
    onRead: (messageId: string[]) => void
    onReply: (value: IHistoryMessage) => void
    onRetry: (value: IHistoryMessage[]) => void
    onForward: (messageId: string, isSingleForward: boolean) => void
    onDelete: (messageId: string) => void
}

const MessengerRoom: React.FC<MessengerBodyPropType> = ({
    parentRef,
    user,
    users,
    isLoading,
    isStopLoad,
    isAdmin,
    websocketMessage,
    historyMessages,
    onLoad,
    onRead,
    onReply,
    onRetry,
    onForward,
    onDelete,
}) => {
    const chatRef = useRef<HTMLDivElement>(null)
    const backwardsRef = useRef<HTMLDivElement>(null)
    const forwardsRef = useRef<HTMLDivElement>(null)
    const prevScrollPosition = useRef(0)

    const [isIntersectingBackwards] = useIntersectionObserver<HTMLDivElement>(backwardsRef, {
        root: parentRef.current,
        rootMargin: '400px 0px 0px 0px',
    })

    const [messagesRefs, setMessagesRefs] = useState<Record<string, React.RefObject<HTMLDivElement>|undefined>>({})
    const [historyMessagesData, setHistoryMessagesData] = useState<IHistoryMessageData[]>([])
    const [websocketMessagesData, setWebsocketMessagesData] = useState<IHistoryMessageData[]>([])
    const [errorMessagesData, setErrorMessagesData] = useState<IHistoryMessageData[]>([])
    const [messagesGroups, setMessagesGroups] = useState<IHistoryMessageGroup[]>([])
    const [responseMessageParentId, setResponseMessageParentId] = useState<string>()
    const [isInit, setIsInit] = useState(false)

    const sender = useMemo(() => UserDto.getMessageUser(user), [user])

    const {
        getMessage,
        getMessageData,
        getMessageGroups,
        isUnreadMessage,
    } = useMessenger()

    const handlerWebsocketMessage = ({ messageType, payload }: TWsMessage) => {
        switch (messageType) {
            case MessengerMessageType.message: {
                const data = payload as IMessengerWsEventMessageNew['payload']

                setWebsocketMessagesData((prevState) => [getMessageData(data), ...prevState])
                setTimeout(() => scrollChatBottom('smooth'), 100)
                onRead([data.id])
                break
            }
            case MessengerMessageType.status: {
                if ('status' in payload && payload.status === MessengerMessageStatus.delivered) {
                    if ('data' in payload) { // on delivered sent message
                        const { data } = payload

                        setWebsocketMessagesData((prevState) => {
                            let isUpdateMessage = false
                            const state = prevState.map((item) => {
                                if (item.data.randomId === data.randomId) {
                                    isUpdateMessage = true
                                    return {
                                        ...item,
                                        data: { ...item.data, ...data },
                                        meta: { ...item.meta, isReceived: true, isError: false },
                                    }
                                }
                                return item
                            })

                            if (!isUpdateMessage) {
                                const {
                                    senderUser = data.senderUserId === sender.id
                                        ? sender
                                        : undefined,
                                    forwardedFromUser = data.forwardedFromUserId
                                        ? users.find((item) => item.id === data.forwardedFromUserId)
                                        : undefined,
                                    inResponseToUser = data.inResponseToMessageId
                                        ? users.find((item) => item.id === data.inResponseToUserId)
                                        : undefined,
                                } = data

                                return [
                                    getMessageData({
                                        ...data,
                                        senderUser,
                                        forwardedFromUser,
                                        inResponseToUser,
                                    }, true),
                                    ...prevState,
                                ]
                            }

                            return state
                        })

                        setErrorMessagesData((prevState) => prevState.filter((item) => {
                            return item.data.randomId !== data.randomId
                        }))

                        setTimeout(() => scrollChatBottom('smooth'), 100)
                    } else if (payload.isDeleted) { // on delivered deleted message
                        setDeleteMessage(payload.randomId)
                    }
                }
                if ('status' in payload && payload.status === MessengerMessageStatus.read) {
                    const data = 'data' in payload ? payload.data : payload
                    setReadMessage(data.randomId)
                }
                if ('status' in payload && payload.status === MessengerMessageStatus.deleted) {
                    const data = 'data' in payload ? payload.data : payload
                    setDeleteMessage(data.randomId)
                }
                break
            }
            case MessengerMessageType.error: {
                if ('requestData' in payload) {
                    const { randomId, ...params } = payload.requestData

                    setErrorMessagesData((prevState) => {
                        let isUpdateMessage = false
                        const state = prevState.map((item) => {
                            if (item.data.randomId === randomId) {
                                isUpdateMessage = true
                            }
                            return item
                        })

                        if (isUpdateMessage) {
                            return state
                        }

                        const data = getMessage({ ...params, randomId, senderUser: sender })

                        return [getMessageData(data, false, true), ...prevState]
                    })
                    setTimeout(() => scrollChatBottom('smooth'), 100)
                } else {
                    // TODO onError -> show alert
                }
                break
            }
            default:
            //
        }
    }

    const handlerHistoryMessages = (data: IHistoryMessage[]) => {
        const dataMessages: IHistoryMessageData[] = []
        const unreadMessages: string[] = []

        data.forEach((item) => {
            if (isUnreadMessage(item, user.id)) {
                unreadMessages.push(item.id)
            }

            dataMessages.push(getMessageData(item, true))
            setMessagesRefs((prevState) => ({ ...prevState, [item.id]: createRef<HTMLDivElement>() }))
        })

        setHistoryMessagesData(dataMessages)

        if (unreadMessages.length) {
            onRead(unreadMessages)
        }
    }

    const handlerRetry = () => {
        onRetry([...errorMessagesData.map((item) => item.data)].reverse())
    }

    const handlerResponse = (messageId: string) => {
        if (messagesRefs[messageId]) { /** Find response parent message in available refs elements */
            scrollChatMessage(messagesRefs[messageId]!)
            focusChatMessage(messagesRefs[messageId]!)
        } else { /** Load more history once */
            setResponseMessageParentId(messageId)
            onLoad()
        }
    }

    function setReadMessage(randomId: number) {
        let isWebsocketMessagesData = false

        const setRead = (message: IHistoryMessageData): IHistoryMessageData => {
            return { ...message, meta: { ...message.meta, isRead: true } }
        }

        setWebsocketMessagesData((prevState) => prevState.map((item) => {
            if (item.data.randomId === randomId) {
                isWebsocketMessagesData = true
                return setRead(item)
            }
            return item
        }))

        if (!isWebsocketMessagesData) {
            setHistoryMessagesData((prevState) => prevState.map((item) => {
                if (item.data.randomId === randomId) {
                    return setRead(item)
                }
                return item
            }))
        }
    }

    function setDeleteMessage(randomId: number) {
        let isWebsocketMessagesData = false

        setWebsocketMessagesData((prevState) => prevState.filter((item) => {
            if (item.data.randomId === randomId) {
                isWebsocketMessagesData = true
                return false
            }
            return true
        }))

        if (!isWebsocketMessagesData) {
            setHistoryMessagesData((prevState) => prevState.filter((item) => {
                return item.data.randomId !== randomId
            }))
        }
    }

    function scrollChatBottom(behavior: ScrollIntoViewOptions['behavior'] = 'auto') {
        if (forwardsRef.current) {
            forwardsRef.current.scrollIntoView({ block: 'end', behavior })
        }
    }

    function scrollChatMessage(
        ref: RefObject<HTMLDivElement>,
        { block = 'nearest', behavior = 'smooth' }: ScrollIntoViewOptions = {},
    ) {
        if (ref?.current) {
            ref.current.scrollIntoView({ block, behavior })
        }
    }

    function focusChatMessage(ref: RefObject<HTMLDivElement>) {
        if (ref?.current) {
            const messageEl = ref.current

            messageEl.classList.add(styleMessage.message_focused)
            setTimeout(() => {
                messageEl.classList.remove(styleMessage.message_focused)
            }, 1000)
        }
    }

    /**
     * Save chat parent node prevent scroll position on change
     */
    useMemo(() => {
        if (parentRef.current) {
            prevScrollPosition.current = parentRef.current.scrollHeight - parentRef.current.scrollTop
        }
    }, [messagesGroups])

    useEffect(() => {
        return () => {
            setIsInit(false)
            setHistoryMessagesData([])
            setWebsocketMessagesData([])
            setErrorMessagesData([])
            setMessagesGroups([])
        }
    }, [])

    useEffect(() => {
        if (!isInit && messagesGroups.length) {
            setIsInit(true)
        }
    }, [isInit, messagesGroups])

    useEffect(() => {
        if (isInit && !isStopLoad && isIntersectingBackwards) {
            onLoad()
        }
    }, [isInit, isStopLoad, isIntersectingBackwards])

    useEffect(() => {
        if (responseMessageParentId && messagesRefs[responseMessageParentId]) {
            setResponseMessageParentId(undefined)
            scrollChatMessage(messagesRefs[responseMessageParentId]!)
            focusChatMessage(messagesRefs[responseMessageParentId]!)
        }
    }, [messagesGroups])

    useEffect(() => {
        setMessagesGroups(getMessageGroups([...errorMessagesData, ...websocketMessagesData, ...historyMessagesData]))
    }, [historyMessagesData, websocketMessagesData, errorMessagesData])

    useEffect(() => {
        if (websocketMessage) {
            handlerWebsocketMessage(websocketMessage)
        }
    }, [websocketMessage])

    useEffect(() => {
        if (historyMessages) {
            handlerHistoryMessages(historyMessages)
        }
    }, [historyMessages])

    /**
     * Scroll chat parent node for keep scroll position on change
     */
    useLayoutEffect(() => {
        if (parentRef.current) {
            parentRef.current.scrollTo({ top: parentRef.current.scrollHeight - prevScrollPosition.current })
        }
    }, [messagesGroups])

    return (
        <div className={style.chat} ref={chatRef}>
            {isLoading && (
                <Loader />
            )}

            {isInit && (
                <div className={style.backwards} ref={backwardsRef} />
            )}

            {messagesGroups.map((group) => (
                <MessengerMessageGroup
                    data={group}
                    key={group.id}
                >
                    {group.messages.map((message, index, items) => (
                        <React.Fragment key={message.data.id}>
                            {index > 0 && items[index - 1].data.senderUserId !== message.data.senderUserId && (
                                <div className={style.avatarWrap}>
                                    <MessengerMessageAvatar
                                        isOwn={message.data.senderUserId === user.id}
                                        data={message.data}
                                    />
                                </div>
                            )}
                            <div className={style.messageWrap}>
                                <MessengerMessage
                                    isOwn={message.data.senderUserId === user.id}
                                    isAdmin={isAdmin}
                                    user={user}
                                    data={message}
                                    ref={messagesRefs[message.data.id]}
                                    onForward={onForward}
                                    onReply={onReply}
                                    onRetry={handlerRetry}
                                    onDelete={onDelete}
                                    onResponse={handlerResponse}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </MessengerMessageGroup>
            ))}

            <div className={style.forwards} ref={forwardsRef} />
        </div>
    )
}

export default MessengerRoom
