import React, { useMemo } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMlmRank } from 'interfaces'
import {
    APP_URL,
    SLIDER_ITEM_CLASS,
    BREAKPOINTS,
} from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    CardContainer,
    Block,
} from 'layout'
import { useFetchMlmReports } from 'containers/ReportsFreedom/hooks'
import { useFetchMlmRankStats } from 'containers/User/hooks'
import {
    PageTitle,
    Slider,
    Card,
    Loader,
    ErrorMsg,
    SvgResource,
} from 'components'
import { useWindowResize } from 'hooks'
import { priceFormat } from 'utils/helpers'
import {
    ReportsMlmActivity,
    ReportsMlmRank,
    ReportsMlmChart,
    ReportsMlmMembers,
} from './components'
import style from './Reports.module.css'

const getReportChartUrl = (id: string) => {
    switch (id) {
        case 'clients':
            return APP_URL.reportsFreedomClients
        case 'active-partners':
            return APP_URL.reportsFreedomActivePartners
        case 'turnover':
            return APP_URL.reportsFreedomTurnover
        case 'income':
            return APP_URL.reportsFreedomIncome
        default:
            return ''
    }
}

const ReportsV2: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const {
        isInitialLoading: isILoadingReports,
        data: dataReports,
        error: reportsError,
    } = useFetchMlmReports()

    const {
        data: dataMlmRankStats,
    } = useFetchMlmRankStats({ enabled: !!dataReports })

    const privilegeActivityRestValue = useMemo(() => {
        if (dataReports && !dataReports.activity.isCompleted) {
            const { activity: { restAmount, restCurrency } } = dataReports
            const currencyOptions = {
                isoCode: restCurrency.code,
                symbol: restCurrency.show_code,
                leftSymbolPlacement: restCurrency.left_currency_symbol_placement,
            }

            return !dataReports.activity.isCompleted
                ? priceFormat(restAmount, currencyOptions)
                : undefined
        }
        return undefined
    }, [dataReports])

    const handlerClickMlmRankMembers = (rank?: IMlmRank) => {
        history.push(APP_URL.reportsPrivilegeMembers, { rank })
    }

    const handlerClickReportsChart = (id: string) => {
        const url = getReportChartUrl(id)

        if (url) {
            history.push(url)
        }
    }

    return (
        <>
            <ContentContainer size="half">
                <PageTitle classes={style.pageTitle} title={t('reports')}>
                    <NavLink title={t('reports')} to={APP_URL.reportsAccounts}>
                        <SvgResource
                            resourceKey="ic_tab_report_svg"
                            width={24}
                            height={24}
                        />
                    </NavLink>
                </PageTitle>

                {isILoadingReports && (
                    <Loader />
                )}

                {!isILoadingReports && dataReports && (
                    <>
                        <Block classes={cn(style.block)}>
                            <ReportsMlmActivity
                                title={
                                    dataReports.activity.isCompleted
                                        ? t('mlm_privilege_activity_completed')
                                        : t('mlm_privilege_activity_uncompleted')
                                }
                                subtitle={(
                                    !dataReports.activity.isCompleted
                                        ? (
                                            <>
                                                {t('mlm_privilege_activity_rest')}
                                                {' '}
                                                <span className={style.restValue}>
                                                    {privilegeActivityRestValue}
                                                </span>
                                            </>
                                        ) : undefined
                                )}
                                imageKey={
                                    dataReports.activity.isCompleted
                                        ? 'activity_completed_png'
                                        : 'activity_uncompleted_png'
                                }
                                imageHeight={60}
                                dataText={t('mlm_privilege_personal_turnover')}
                                dataValue={dataReports.activity.turnover}
                                options={{ subtitle: { danger: !dataReports.activity.isCompleted } }}
                            />
                        </Block>

                        {dataReports.rank.current.id >= 2 ? (
                            <Slider
                                arrows
                                classes={cn(
                                    style.slider,
                                    { [style.slider_gapBottom]: dataReports.rank.progressList.length === 1 },
                                )}
                            >
                                {dataReports.rank.progressList.map((item) => (
                                    <NavLink
                                        className={cn(SLIDER_ITEM_CLASS, style.sliderItem, style.sliderItem_link)}
                                        to={APP_URL.reportsFreedomRanks}
                                        key={item.name || item.message}
                                    >
                                        <Block classes={style.block}>
                                            <ReportsMlmRank
                                                current={dataReports.rank.current}
                                                next={dataReports.rank.next}
                                                progress={item}
                                            />
                                        </Block>
                                    </NavLink>
                                ))}
                            </Slider>
                        ) : (
                            <Block classes={cn(style.block, style.block_margin)}>
                                <div className={style.blockTitle}>
                                    {dataReports.rank.current.name}
                                </div>
                                <div className={style.blockContent}>
                                    {dataReports.rank.progressList.length && (
                                        <div className={style.blockTextInfo}>
                                            {dataReports.rank.progressList[0].message}
                                        </div>
                                    )}
                                </div>
                            </Block>
                        )}

                        <CardContainer classes={style.charts}>
                            {dataReports.blockList.map((item) => (
                                <Card.Item
                                    classes={cn(style.card, style.card_link)}
                                    key={item.id}
                                    onClick={() => handlerClickReportsChart(item.id)}
                                >
                                    <Block classes={cn(style.block, style.blockCard)}>
                                        <ReportsMlmChart data={item} />
                                    </Block>
                                </Card.Item>
                            ))}
                        </CardContainer>

                        {windowWidth < BREAKPOINTS.desktop && (
                            !isILoadingReports && dataMlmRankStats && (
                                <Block classes={cn(style.stats, style.block)}>
                                    <ReportsMlmMembers
                                        data={dataMlmRankStats}
                                        options={{ options: { aspectRatio: 3 } }}
                                        onClickMembers={handlerClickMlmRankMembers}
                                        onClickAllMembers={handlerClickMlmRankMembers}
                                    />
                                </Block>
                            )
                        )}
                    </>
                )}

                {reportsError && (
                    <ErrorMsg error={reportsError} />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    {!isILoadingReports && dataMlmRankStats && (
                        <Block classes={cn(style.stats, style.block)}>
                            <ReportsMlmMembers
                                data={dataMlmRankStats}
                                options={{ options: { aspectRatio: 2 } }}
                                onClickMembers={handlerClickMlmRankMembers}
                                onClickAllMembers={handlerClickMlmRankMembers}
                            />
                        </Block>
                    )}
                </SideBarContainer>
            )}
        </>
    )
}

export default ReportsV2
