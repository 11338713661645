import React, { useMemo } from 'react'
import {
    Switch,
    Route,
    matchPath,
    useParams,
    useLocation,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { AppScheme } from 'enums'
import { APP_URL, BREAKPOINTS, STORE_TYPE_CPA_NO_GOODS } from 'config/app'
import { ContentContainer, SideBarContainer } from 'layout'
import {
    PageTitle,
    Breadcrumbs,
    StoreNav,
    StoreContacts,
    StoreBasketBar,
} from 'components'
import {
    StoreProfile,
    StoreCatalog,
    StoreInfo,
    StoreBasket,
    StoreOrders,
    StoreOrderMake,
    StoreOrderPayment,
} from 'containers'
import { useFetchShopsStatic, useFetchBasket } from 'containers/Market/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { useWindowResize } from 'hooks'
import { useFetchStoreProfile } from './hooks'
import style from './Store.module.css'

const Store: React.FC = () => {
    const { id, catalogId } = useParams<{ id: string, catalogId?: string }>()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const [windowWidth] = useWindowResize()

    const isMlmUser = useSelector(userSelectors.mlm)

    const storeId = useMemo(() => Number(id), [id])

    const { data: dataStoreProfile } = useFetchStoreProfile({
        id: storeId,
    }, {
        enabled: !Number.isNaN(storeId),
    })

    const { data: dataShopsStatic } = useFetchShopsStatic({
        schema: AppScheme.cc,
    }, {
        enabled: isMlmUser,
    })

    const { data: dataBasket } = useFetchBasket({
        storeId,
    }, {
        enabled: !!dataStoreProfile && !matchPath(pathname, APP_URL.basketFromOrder),
    })

    const breadcrumbs = useMemo(() => {
        const [mainShop] = dataShopsStatic || []
        const result: IBreadcrumb[] = [{ id: 1, name: t('my_stores'), url: APP_URL.favoriteShops }]

        if (mainShop?.id === storeId) {
            return [...result, { id: 2, name: t('our_store') }]
        }
        if (dataStoreProfile) {
            return [...result, { id: 2, name: dataStoreProfile.name }]
        }

        return result
    }, [dataStoreProfile, dataShopsStatic])

    const isShowPageTitle = useMemo(() => {
        const matchOrderMake = matchPath(pathname, { path: APP_URL.orderMake, exact: true })
        const matchOrderPayment = matchPath(pathname, { path: APP_URL.orderPayment, exact: true })

        return isMlmUser && !matchOrderMake && !matchOrderPayment
    }, [isMlmUser, pathname])

    const isShowNav = useMemo(() => {
        if (dataStoreProfile) {
            const { gateway_type: gatewayType } = dataStoreProfile
            const matchProfile = matchPath(pathname, { path: APP_URL.storeProfile, exact: true })
            const matchOrderMake = matchPath(pathname, { path: APP_URL.orderMake, exact: true })
            const matchOrderPayment = matchPath(pathname, { path: APP_URL.orderPayment, exact: true })

            return !matchProfile && !matchOrderMake && !matchOrderPayment && gatewayType !== STORE_TYPE_CPA_NO_GOODS
        }
        return false
    }, [pathname, dataStoreProfile])

    const isShowDesc = useMemo(() => {
        const matchProfile = matchPath(pathname, { path: APP_URL.storeProfile, exact: true })
        return !matchProfile
    }, [pathname])

    const isShowBar = useMemo(() => {
        const matchStore = matchPath(pathname, { path: APP_URL.store, exact: true })
        const matchCatalog = matchPath(pathname, { path: APP_URL.catalog, exact: true })

        return matchStore || matchCatalog
    }, [pathname, dataBasket])

    return (
        <>
            <ContentContainer
                classes={cn(
                    style.content,
                    { [style.content_bottomGap]: isShowBar && !!dataBasket?.content?.length },
                )}
                size="half"
            >
                {isShowPageTitle && (
                    <PageTitle>
                        <Breadcrumbs items={breadcrumbs} />
                    </PageTitle>
                )}

                {isShowNav && (
                    <StoreNav
                        storeId={storeId}
                        catalogId={Number(catalogId)}
                        data={dataStoreProfile}
                    />
                )}

                <Switch>
                    <Route path={APP_URL.store} component={StoreCatalog} exact />
                    <Route path={APP_URL.storeProfile} component={StoreProfile} />
                    <Route path={APP_URL.catalog} component={StoreCatalog} />
                    <Route path={APP_URL.basketFromOrder} component={StoreBasket} />
                    <Route path={APP_URL.basket} component={StoreBasket} />
                    <Route path={APP_URL.orders} component={StoreOrders} />
                    <Route path={APP_URL.orderMake} component={StoreOrderMake} />
                    <Route path={APP_URL.orderPayment} component={StoreOrderPayment} />
                </Switch>

                {isShowBar && !!dataBasket?.content?.length && (
                    <StoreBasketBar
                        storeId={storeId}
                        data={dataBasket}
                        storeProfile={dataStoreProfile}
                    />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    <StoreInfo data={dataStoreProfile} showDesc={isShowDesc} />

                    <StoreContacts data={dataStoreProfile} />
                </SideBarContainer>
            )}
        </>
    )
}

export default Store
