import React, {
    useRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    IHistoryMessage,
    IMessengerMessage,
    IMessengerWsChannelMessageProps,
    IMessengerWsChatMessageProps,
    IMessengerWsEventMessageError,
    IMessengerWsEventMessageNew,
    IMessengerWsEventMessageStatus,
    IMessengerWsForwardMessageProps,
    IMessengerWsStatusMessageProps,
} from 'interfaces'
import { TConversationListResponse } from 'services/ChatMicroService'
import { MessengerMessageType, MessengerMessageStatus } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { Block, ContentContainer, SideBarContainer } from 'layout'
import {
    useMessenger,
    useFetchInfiniteHistoryChat,
    useSetQueryDataConversationList,
} from 'containers/Messenger/hooks'
import { useFetchProfile } from 'containers/User/hooks'
import { useWindowResize } from 'hooks'
import { MessageAction } from 'form-actions'
import { NetworkService } from 'services'
import { getRequestError, showAlertNotify } from 'utils/helpers'
import { UserDto } from 'dto'
import { useAppSelector } from 'store'
import {
    MessengerBody,
    MessengerContainer,
    MessengerConversationList,
    MessengerFooter,
    MessengerHeader,
    MessengerHeaderChat,
    MessengerItemChannel,
    MessengerItemChat,
    MessengerMenu,
    MessengerMessageReply,
    MessengerRoom,
    MessengerSearch,
    MessengerSearchList,
} from '../components'
import { useMessengerChatUsersData } from '../hooks'
import style from './MessengerChat.module.css'

type TWsMessage = IMessengerWsEventMessageNew
    | IMessengerWsEventMessageStatus
    | IMessengerWsEventMessageError

type TRetryMessageProps = (IMessengerWsChatMessageProps|IMessengerWsChannelMessageProps)[]

const LIMIT_HISTORY = 50
const LIMIT_CONVERSATION_LIST = 10

const MessengerChat: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const chatRef = useRef<HTMLDivElement>(null)

    const user = useAppSelector((state) => state.user)

    const [conversationListParams] = useState({ userId: user.id, page: 1, paginatedBy: LIMIT_CONVERSATION_LIST })
    const [websocketMessage, setWebsocketMessage] = useState<TWsMessage>()
    const [historyMessages, setHistoryMessages] = useState<IHistoryMessage[]>()
    const [chatUserIds, setChatUserIds] = useState<number[]>([])
    const [replyMessage, setReplyMessage] = useState<IHistoryMessage>()
    const [retryMessages, setRetryMessages] = useState<TRetryMessageProps>()
    const [statusMessages, setStatusMessages] = useState<IMessengerWsStatusMessageProps[]>()
    const [forwardMessages, setForwardMessages] = useState<IMessengerWsForwardMessageProps[]>()
    const [forwardMessageId, setForwardMessageId] = useState<string>()
    const [searchText, setSearchText] = useState('')
    const [isHideLoaderHistoryChat, setIsHideLoaderHistoryChat] = useState(false)
    const [isSingleForward, setIsSingleForward] = useState(false)

    const chatUserId = useMemo(() => Number(id), [id])
    const isChatUser = useMemo(() => !Number.isNaN(chatUserId) && user.id !== chatUserId, [chatUserId])

    const {
        data: dataProfile,
        refetch: refetchDataProfile,
    } = useFetchProfile({
        userId: chatUserId,
    }, {
        enabled: isChatUser,
    })

    const {
        isInitialLoading: isLoadingHistoryChat,
        data: dataHistoryChat,
        error: errorHistoryChat,
        fetchNextPage: fetchNextPageHistoryChat,
    } = useFetchInfiniteHistoryChat({
        userId: user.id,
        chatUserId,
        withUsersInfo: true,
        page: 1,
        paginatedBy: LIMIT_HISTORY,
    }, {
        enabled: isChatUser,
        staleTime: 0,
        cacheTime: 0,
        refetchOnReconnect: false, // for case offline/online
    })

    const {
        updateLastMessageConversationList,
        removeLastMessageConversationList,
        getUnreadMessageProps,
        getDeleteMessageProps,
        getRetryMessageProps,
        getChatUserId,
    } = useMessenger()

    const { data: dataChatUsersData } = useMessengerChatUsersData(chatUserIds)

    const { setQueryData: setQueryDataConversationList } = useSetQueryDataConversationList()

    const users = useMemo(() => {
        return dataProfile ? [UserDto.getMessageUser(dataProfile)] : []
    }, [dataProfile])

    const handlerLoadHistoryChat = () => {
        if (!isLoadingHistoryChat) {
            fetchNextPageHistoryChat()
        }
    }

    const handlerLoadConversationList = (value: TConversationListResponse) => {
        setChatUserIds(value.data.reduce<number[]>((acc, item) => {
            return 'channelId' in item ? acc : [...acc, getChatUserId(user.id, item)]
        }, []))
    }

    const handlerMessage = (value: TWsMessage) => {
        filterChatMessage(value)
        filterForwardMessage(value)
    }

    const handlerReadMessages = (value: string[]) => {
        setStatusMessages([getUnreadMessageProps({ messageIds: value })])
    }

    const handlerRetryMessages = (value: IMessengerMessage[]) => {
        setRetryMessages(value
            .filter(({ receiverUserId }) => !!receiverUserId)
            .map(({ randomId, receiverUserId, text }) => {
                return getRetryMessageProps({ randomId, toUserId: receiverUserId!, text: text ?? '' })
            }))
    }

    const handlerReplyMessage = (value: IHistoryMessage) => {
        setReplyMessage(value)
    }

    const handlerForwardMessage = (messageId: string, isSingle: boolean) => {
        setForwardMessageId(messageId)
        setIsSingleForward(isSingle)
    }

    const handlerDeleteMessage = (messageId: string) => {
        setStatusMessages([getDeleteMessageProps({ messageId })])
    }

    const handlerSearch = (value: string) => {
        setSearchText(value)
    }

    const handlerSendForward = (value: IMessengerWsForwardMessageProps[]) => {
        setForwardMessages(value)
    }

    const handlerCloseReply = () => {
        setReplyMessage(undefined)
    }

    const handlerBlockUser = ({ userId }: { userId: number }) => {
        blockUserAction(userId)
    }

    const handlerUnBlockUser = ({ userId }: { userId: number }) => {
        unBlockUserAction(userId)
    }

    const handlerErrorMessage = () => {
        showAlertNotify({ type: 'error', message: t('update_error') })
    }

    const handlerChatBan = () => {
        showAlertNotify({
            type: 'error',
            message: t('chat_ban').replace('%s', `${dataProfile?.name ?? ''} ${dataProfile?.surname ?? ''}`),
        })
    }

    function filterChatMessage(value: TWsMessage) {
        const { messageType, payload } = value
        let isCurrentChatMessage = false

        switch (messageType) {
            case MessengerMessageType.message: {
                // @ts-ignore
                const { senderUserId, receiverUserId } = payload
                const isSentMessage = senderUserId === user.id && receiverUserId === chatUserId
                const isReceivedMessage = senderUserId === chatUserId && receiverUserId === user.id

                if (isSentMessage || isReceivedMessage) { // on sent/received new message
                    isCurrentChatMessage = true
                }
                break
            }
            case MessengerMessageType.status: {
                /**
                 * Status delivered for chat sender user
                 */
                if ('status' in payload && payload.status === MessengerMessageStatus.delivered) {
                    const message = 'data' in payload ? payload.data : payload
                    const {
                        senderUserId,
                        receiverUserId,
                        inResponseToMessageId,
                        isDeleted,
                    } = message
                    const isSentMessage = senderUserId === user.id && receiverUserId === chatUserId

                    isCurrentChatMessage = isSentMessage

                    if (isSentMessage && inResponseToMessageId) {
                        /** reset reply message state */
                        setReplyMessage((prevState) => {
                            return prevState?.id === inResponseToMessageId ? undefined : prevState
                        })
                    }
                    if (isDeleted) {
                        /** remove chat last message in conversation list */
                        setQueryDataConversationList(conversationListParams, (queryData) => {
                            return queryData && 'pages' in queryData
                                ? {
                                    ...queryData,
                                    pages: queryData.pages.map((page) => ({
                                        ...page,
                                        data: page.data.map((item) => {
                                            return removeLastMessageConversationList(item, message.id)
                                        }),
                                    })),
                                }
                                : queryData
                        })
                    } else {
                        /** update chat last message in conversation list */
                        setQueryDataConversationList(conversationListParams, (queryData) => {
                            return queryData && 'pages' in queryData
                                ? {
                                    ...queryData,
                                    pages: queryData.pages.map((page) => ({
                                        ...page,
                                        data: page.data.map((item) => updateLastMessageConversationList(
                                            item,
                                            receiverUserId, {
                                                ...message,
                                                viewsCount: 0,
                                            },
                                        )),
                                    })),
                                }
                                : queryData
                        })
                    }
                }
                /**
                 * Status read for chat sender user
                 */
                if ('status' in payload && payload.status === MessengerMessageStatus.read) {
                    const message = 'data' in payload ? payload.data : payload
                    const { senderUserId, receiverUserId } = message
                    const isSentMessage = senderUserId === user.id && receiverUserId === chatUserId

                    isCurrentChatMessage = isSentMessage

                    /** update chat read status in conversation list */
                    setQueryDataConversationList(conversationListParams, (queryData) => {
                        return queryData && 'pages' in queryData
                            ? {
                                ...queryData,
                                pages: queryData.pages.map((page) => ({
                                    ...page,
                                    data: page.data.map((item) => updateLastMessageConversationList(
                                        item,
                                        receiverUserId,
                                        message,
                                    )),
                                })),
                            }
                            : queryData
                    })
                }
                /**
                 * Status deleted for chat receiver user
                 */
                if ('status' in payload && payload.status === MessengerMessageStatus.deleted) {
                    const message = 'data' in payload ? payload.data : payload
                    const { senderUserId, receiverUserId } = message
                    const isReceivedMessage = senderUserId === chatUserId && receiverUserId === user.id

                    isCurrentChatMessage = isReceivedMessage

                    /** remove chat last message in conversation list */
                    setQueryDataConversationList(conversationListParams, (queryData) => {
                        return queryData && 'pages' in queryData
                            ? {
                                ...queryData,
                                pages: queryData.pages.map((page) => ({
                                    ...page,
                                    data: page.data.map((item) => removeLastMessageConversationList(item, message.id)),
                                })),
                            }
                            : queryData
                    })
                }
                break
            }
            case MessengerMessageType.error: {
                if ('requestData' in payload && 'toUserId' in payload.requestData && payload.requestData.toUserId === chatUserId) {
                    isCurrentChatMessage = true
                }
                break
            }
            default:
                //
        }

        if (isCurrentChatMessage) {
            setWebsocketMessage(value)
        }
    }

    function filterForwardMessage(value: TWsMessage) {
        const { messageType, payload } = value

        let isForwardMessage = false
        let isRedirect = false
        let urlGoTo = ''

        switch (messageType) {
            case MessengerMessageType.status: {
                /**
                 * Status delivered for chat sender user
                 */
                if ('status' in payload && payload.status === MessengerMessageStatus.delivered) {
                    const message = 'data' in payload ? payload.data : payload
                    const { senderUserId } = message

                    if (senderUserId === user.id && message.forwardedMessageId) {
                        /** compare sent forward message id */
                        setForwardMessageId((prevState) => {
                            if (prevState === message.forwardedMessageId) {
                                isForwardMessage = true
                            }
                            return prevState
                        })
                        /** check is set single forward message receiver */
                        setIsSingleForward((prevState) => {
                            if (prevState) {
                                isRedirect = true
                            }
                            return prevState
                        })
                    }
                }
                break
            }
            default:
                //
        }

        if ('status' in payload && isForwardMessage && isRedirect) {
            const message = 'data' in payload ? payload.data : payload

            if ('receiverUserId' in message && message.receiverUserId) {
                urlGoTo = generatePath(APP_URL.messengerChat, { id: message.receiverUserId })
            }
            if ('channelId' in message && message.channelId) {
                urlGoTo = generatePath(APP_URL.messengerChannel, { id: message.channelId })
            }
            if (urlGoTo) {
                history.push(urlGoTo)
            }
        }
    }

    function blockUserAction(userId: number) {
        NetworkService.blockUser({ userId })
            .then(({ data }) => {
                refetchDataProfile()
                showAlertNotify({ type: 'success', message: data })
            })
            .catch((err) => {
                showAlertNotify({ type: 'success', message: getRequestError(err) })
            })
    }

    function unBlockUserAction(userId: number) {
        NetworkService.unBlockUser({ userId })
            .then(({ data }) => {
                refetchDataProfile()
                showAlertNotify({ type: 'success', message: data })
            })
            .catch((err) => {
                showAlertNotify({ type: 'success', message: getRequestError(err) })
            })
    }

    useEffect(() => {
        if (!isChatUser) {
            history.replace(APP_URL.messenger)
        }

        return () => {
            setHistoryMessages(undefined)
            setWebsocketMessage(undefined)
            setReplyMessage(undefined)
            setRetryMessages(undefined)
            setForwardMessages(undefined)
            setForwardMessageId(undefined)
            setIsHideLoaderHistoryChat(false)
        }
    }, [chatUserId])

    useEffect(() => {
        if (dataHistoryChat) {
            const { pages } = dataHistoryChat
            const pagesLen = pages.length

            setHistoryMessages(pages.reduce<IHistoryMessage[]>((acc, item) => [...acc, ...item.data], []))

            if (pagesLen && pages[pagesLen - 1].currentPage >= pages[pagesLen - 1].totalPages) {
                setIsHideLoaderHistoryChat(true)
            }
        }
    }, [dataHistoryChat])

    useEffect(() => {
        if (errorHistoryChat) {
            const [msg, error] = errorHistoryChat
            const { response } = error || {}

            showAlertNotify({ type: 'error', message: response?.data.errorMsg || msg })
        }
    }, [errorHistoryChat])

    return (
        <>
            <ContentContainer size="half">
                <MessengerContainer
                    isSingleForward={isSingleForward}
                    forwardMessageId={forwardMessageId}
                    onSendForward={handlerSendForward}
                >
                    <MessengerHeader>
                        <MessengerHeaderChat
                            chatUserProfile={dataProfile}
                            onBlockUser={handlerBlockUser}
                            onUnBlockUser={handlerUnBlockUser}
                        />
                    </MessengerHeader>
                    <MessengerBody ref={chatRef}>
                        <MessengerRoom
                            isLoading={isLoadingHistoryChat}
                            isStopLoad={isHideLoaderHistoryChat}
                            parentRef={chatRef}
                            user={user}
                            users={users}
                            historyMessages={dataHistoryChat && historyMessages} // fix reset prev historyMessages
                            websocketMessage={dataHistoryChat && websocketMessage} // fix reset prev websocketMessage
                            key={chatUserId}
                            onLoad={handlerLoadHistoryChat}
                            onRead={handlerReadMessages}
                            onRetry={handlerRetryMessages}
                            onReply={handlerReplyMessage}
                            onForward={handlerForwardMessage}
                            onDelete={handlerDeleteMessage}
                        />
                    </MessengerBody>
                    <MessengerFooter>
                        <MessageAction
                            isBan={dataProfile ? !dataProfile.chat : false}
                            isEnabled={isChatUser && !!dataProfile}
                            isFocus={!!replyMessage}
                            toUserId={chatUserId}
                            replyMessage={replyMessage}
                            retryMessages={retryMessages}
                            statusMessages={statusMessages}
                            forwardMessages={forwardMessages}
                            key={chatUserId}
                            onMessage={handlerMessage}
                            onError={handlerErrorMessage}
                            onChatBan={handlerChatBan}
                        >
                            {replyMessage && (
                                <MessengerMessageReply
                                    data={replyMessage}
                                    onClose={handlerCloseReply}
                                />
                            )}
                        </MessageAction>
                    </MessengerFooter>
                </MessengerContainer>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && isChatUser && (
                <SideBarContainer classes={style.sidebar} position="left">
                    <Block classes={style.blockSideBar}>
                        <MessengerSearch
                            isCompact
                            classes={style.searchSideBar}
                            onSearch={handlerSearch}
                        >
                            <MessengerMenu />
                        </MessengerSearch>
                        {!!searchText && (
                            <MessengerSearchList
                                classesTitle={style.searchListTitle}
                                classesItem={style.searchListItem}
                                user={user}
                                searchText={searchText}
                            />
                        )}
                        {!searchText && (
                            <MessengerConversationList
                                user={user}
                                limit={conversationListParams.paginatedBy}
                                onLoad={handlerLoadConversationList}
                                render={(data) => (
                                    'channelId' in data ? (
                                        <MessengerItemChannel
                                            data={data}
                                            user={user}
                                        />
                                    ) : (
                                        <MessengerItemChat
                                            isActive={data.starterUserId === user.id
                                                ? data.followerUserId === chatUserId
                                                : data.starterUserId === chatUserId}
                                            data={data}
                                            user={user}
                                            chatUserData={dataChatUsersData[data.starterUserId === user.id
                                                ? data.followerUserId
                                                : data.starterUserId]}
                                        />
                                    )
                                )}
                            />
                        )}
                    </Block>
                </SideBarContainer>
            )}
        </>
    )
}

export default MessengerChat
