import React from 'react'

import { SvgResource } from 'components'

const Logo: React.FC = () => {
    return (
        <SvgResource
            resourceKey="coffeecell_logo_svg"
            width={161}
            height={42}
        />
    )
}

export default Logo
