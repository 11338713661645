import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import {
    IUserData,
    IMessengerChat,
    IMessengerChannel,
    IMessengerWsConversationStatusMessageProps,
} from 'interfaces'
import { TEventMessage } from 'form-actions/MessageConversationAction'
import { MessengerMessageStatus, MessengerMessageType } from 'enums'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    Loader,
    Button,
    ErrorMsg,
    NoDataInfo,
    InfiniteScroll,
} from 'components'
import { useFetchFriends } from 'containers/User/hooks'
import { MessageConversationAction } from 'form-actions'
import { useAppSelector } from 'store'
import {
    MessengerMenu,
    MessengerSearch,
    MessengerSearchList,
    MessengerConversationChat,
    MessengerConversationChannel,
} from '../components'
import {
    useMessenger,
    useMessengerChatUsersData,
    useFetchInfiniteConversationList,
    useInvalidateConversationCountsNew,
} from '../hooks'
import style from './MessengerConversationList.module.css'

type TConversationStatusParams = IMessengerWsConversationStatusMessageProps

const LIMIT_CONVERSATION_LIST = 10

const MessengerConversationList: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const debouncedInvalidate = useDebouncedCallback(invalidateAction, 500)

    const user = useAppSelector((state) => state.user)

    const [searchText, setSearchText] = useState('')
    const [chatUserIds, setChatUserIds] = useState<number[]>([])
    const [chatUsersData, setChatUsersData] = useState<Record<string, IUserData>>({})
    const [conversationStatusParams, setConversationStatusParams] = useState<TConversationStatusParams>()
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const {
        getChatUserId,
        getReadConversationProps,
        getUnReadConversationProps,
        findConversationChat,
        findConversationChannel,
    } = useMessenger()

    const {
        isInitialLoading: isLoadingConversationList,
        data: dataConversationList,
        error: errorConversationList,
        refetch: refetchConversationList,
        fetchNextPage: fetchNextPageConversationList,
    } = useFetchInfiniteConversationList({
        userId: user.id,
        withUnreadMessagesCount: true,
        page: 1,
        paginatedBy: LIMIT_CONVERSATION_LIST,
    }, {
        enabled: !!user,
        staleTime: 0,
    })

    const { data: dataFriends } = useFetchFriends({}, {
        enabled: !!dataConversationList && !dataConversationList.pages[0].data.length,
    })

    const { data: dataChatUsersData } = useMessengerChatUsersData(chatUserIds)

    const { invalidate: invalidateConversationCountsNew } = useInvalidateConversationCountsNew()

    const handlerOnLoad = () => {
        fetchNextPageConversationList()
    }

    const handlerSearch = (value: string) => {
        setSearchText(value)
    }

    const handlerClickNewChat = () => {
        history.push(APP_URL.messengerChatNew)
    }

    const handlerMarkReadChat = (id: number) => {
        setConversationStatusParams(getReadConversationProps({ toUserId: id }))
    }

    const handlerMarkUnReadChat = (id: number) => {
        setConversationStatusParams(getUnReadConversationProps({ toUserId: id }))
    }

    const handlerMarkReadChannel = (id: string) => {
        // FIXME in KW-184 KW-185
        return
        // eslint-disable-next-line
        setConversationStatusParams(getReadConversationProps({ channelId: id }))
    }

    const handlerMarkUnReadChannel = (id: string) => {
        // FIXME in KW-184 KW-185
        return
        // eslint-disable-next-line
        setConversationStatusParams(getUnReadConversationProps({ channelId: id }))
    }

    const handlerOnConversationMessage = (value: TEventMessage) => {
        const conversationList = dataConversationList?.pages
            .reduce<(IMessengerChat | IMessengerChannel)[]>((acc, page) => ([...acc, ...(page.data)]), [])

        filterConversationMessage(value, conversationList)
    }

    function filterConversationMessage(
        { messageType, payload }: TEventMessage,
        conversationList?: (IMessengerChat | IMessengerChannel)[],
    ) {
        let isCurrentUserConversationMessage = false

        switch (messageType) {
            case MessengerMessageType.message: {
                if ('channelId' in payload && payload.channelId) { // channel message
                    isCurrentUserConversationMessage = !!findConversationChannel(payload.channelId, conversationList)
                }
                if ('receiverUserId' in payload && payload.receiverUserId) { // chat message
                    isCurrentUserConversationMessage = !!findConversationChat(payload.receiverUserId, conversationList)
                }
                break
            }
            case MessengerMessageType.status: {
                if ('status' in payload && payload.status === MessengerMessageStatus.delivered) {
                    if ('channelId' in payload) { // channel message
                        isCurrentUserConversationMessage = !!findConversationChannel(
                            payload.channelId,
                            conversationList,
                        )
                    }
                    if ('receiverUserId' in payload) { // chat message
                        isCurrentUserConversationMessage = !!findConversationChat(
                            payload.receiverUserId,
                            conversationList,
                        )
                    }
                }
                break
            }
            default:
                //
        }

        if (isCurrentUserConversationMessage) {
            debouncedInvalidate()
        }
    }

    function invalidateAction() {
        refetchConversationList()
        invalidateConversationCountsNew()
    }

    useEffect(() => {
        if (dataConversationList) {
            const { pages } = dataConversationList
            const pagesLen = pages.length
            const lastPage = pagesLen ? pages[pagesLen - 1] : undefined

            if (lastPage) {
                setChatUserIds((prevState) => {
                    const userIds = lastPage.data.reduce<number[]>((acc, item) => {
                        return 'channelId' in item ? acc : [...acc, getChatUserId(user.id, item)]
                    }, [])
                    return [...prevState, ...userIds]
                })
            }
            if (lastPage && lastPage.currentPage === lastPage.totalPages) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataConversationList])

    useEffect(() => {
        if (dataChatUsersData) {
            setChatUsersData((prevState) => ({ ...prevState, ...dataChatUsersData }))
        }
    }, [dataChatUsersData])

    return (
        <ContentContainer size="half">
            <Block classes={style.block}>
                {!isLoadingConversationList && !!dataConversationList?.pages[0].data.length && (
                    <MessengerSearch
                        classes={style.search}
                        isDisabled={!dataConversationList}
                        onSearch={handlerSearch}
                    >
                        <MessengerMenu />
                    </MessengerSearch>
                )}

                {isLoadingConversationList && (
                    <Loader />
                )}

                {!isLoadingConversationList && errorConversationList?.length && (
                    <ErrorMsg error={errorConversationList[0]} />
                )}

                {!isLoadingConversationList && dataConversationList && !dataConversationList.pages[0].data.length && (
                    <>
                        <NoDataInfo
                            isShowImg
                            classesText={style.noDataText}
                            text={t('chat_no_messages')}
                        />
                        {!!dataFriends?.total && (
                            <Button
                                classes={style.actionChat}
                                size="size46"
                                onClick={handlerClickNewChat}
                            >
                                {t('start_chat')}
                            </Button>
                        )}
                    </>
                )}
                {searchText && (
                    <MessengerSearchList
                        user={user}
                        searchText={searchText}
                    />
                )}
                {dataConversationList && !searchText && (
                    <InfiniteScroll
                        options={{ rootMargin: '0px 0px 25% 0px' }}
                        isHideLoader={isHideInfiniteLoader}
                        onLoad={handlerOnLoad}
                    >
                        <div className={style.list}>
                            {dataConversationList.pages.map((page) => (
                                <React.Fragment key={page.currentPage}>
                                    {page.data.map((item) => (
                                        <React.Fragment key={item.id}>
                                            {'channelId' in item ? (
                                                <MessengerConversationChannel
                                                    classes={style.listItem}
                                                    data={item}
                                                    user={user}
                                                    onMarkRead={handlerMarkReadChannel}
                                                    onUnMarkRead={handlerMarkUnReadChannel}
                                                />
                                            ) : (
                                                <MessengerConversationChat
                                                    classes={style.listItem}
                                                    data={item}
                                                    user={user}
                                                    chatUserData={chatUsersData[item.starterUserId === user.id
                                                        ? item.followerUserId
                                                        : item.starterUserId]}
                                                    onMarkRead={handlerMarkReadChat}
                                                    onUnMarkRead={handlerMarkUnReadChat}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    </InfiniteScroll>
                )}
            </Block>

            <MessageConversationAction
                message={conversationStatusParams}
                onMessage={handlerOnConversationMessage}
            />
        </ContentContainer>
    )
}

export default MessengerConversationList
